<template>
  <div class="d-flex flex-nowrap align-center">
    <document-ref-icon
      :color="accountColor"
      :visible="showIcon"
      icon-name="i.Accounts"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column flex-nowrap">
      <div class="text-no-wrap d-flex align-baseline">
        <span
          class="text-truncate max-name-width"
          :class="{'font-weight-bold': emphasis, [accountNameColor]: accountNameColor}"
          :style="[accountNameHexColor]"
        >{{accountName}}</span>
        <span
          class="number text-truncate maxW200px"
          :class="{'number-size':!emphasis, 'number-emphasis-size': emphasis}"
        >
          {{$t('t.Number#')}} {{accountNumber}}
        </span>
      </div>
      <div class="d-flex flex-rox flex-nowrap ref-detail ">
        <document-name-ref
          class="mr-1"
          :id="businessDivisionId"
          :cache-type="cacheTypeCompany"
          :show-icon="false"
          icon-name="i.BusinessDivisions"
          mixed
        />
        -
        <document-name-ref
          class="ml-1"
          :id="workQueueId"
          :cache-type="cacheTypeWorkQueue"
          :show-icon="false"
          icon-name="i.WorkQueue"
          mixed
        />
      </div>
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    accountColor () {
      return this.cache?.doc?.color
    },
    cacheTypeCompany () {
      return ClientCache.CacheType.BusinessDivisionRef
    },
    cacheTypeWorkQueue () {
      return ClientCache.CacheType.WorkQueueRef
    },
    accountName () {
      return this.cache?.doc?.name
    },
    accountNameColor () {
      if (!this.color || this.color?.startsWith('#')) { return }
      return `${this.color}+'--text'`
    },
    accountNameHexColor () {
      if (!this.color?.startsWith('#')) { return {} }
      return { color: this.color }
    },
    accountNumber () {
      return this.cache?.doc?.number
    },
    companyId () {
      return this.cache?.doc?.companyId
    },
    businessDivisionId () {
      return this.cache?.doc?.businessDivisionId
    },
    workQueueId () {
      return this.cache?.doc?.workQueueId
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: true,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  data () {
    return {
      actionsShow: false,
      cacheType: ClientCache.CacheType.AccountRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    color: String,
    emphasis: Boolean,
    mixed: Boolean,
    iconProps: Object,
    showIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
@css {
  .max-name-width {
    max-width: min(40vw, 300px);
  }
}

.number
  margin-left 0.5ch
  white-space nowrap

.number-size
  font-size 12px

.number-emphasis-size
  font-size 16px
</style>
